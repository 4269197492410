import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import GET_ARTICLES from "../class/getArticles";

const [d, w, mq] = [document, window, window.matchMedia("(min-width:900px)")];

const domReady = TPL => {
  new BLOCKS;
};

class BLOCKS {
  constructor() {
    this.opening();
    gsap.registerPlugin(ScrollTrigger);
    this.recruit();
    this.news();
  }

  opening() {
    const Mv = d.querySelector('.mainvisual');
    w.addEventListener('load', function(){
      Mv.classList.add('loaded');
    })
  }

  recruit() {
    if(mq.matches){
      gsap.fromTo(
        ".recruit_bg",
        {
          width: "0",
          height: "0",
        },
        {
          width: "100%",
          height: "100%",
          scrollTrigger: {
            trigger: ".block-company .btn_wrap",
            start: "bottom 60%",
            end: 'bottom 40%',
            scrub: true,
            once: true,
            // markers: true,
          },
        },
      );
    } else {
      gsap.fromTo(
        ".recruit_bg",
        {
          width: "0",
          height: "0",
        },
        {
          width: "130%",
          height: "100%",
          scrollTrigger: {
            trigger: ".block-company .btn_wrap",
            start: "bottom 60%",
            end: 'bottom 40%',
            scrub: true,
            once: true,
            // markers: true,
          },
        },
      );
    }

    const fadeInItems = document.querySelectorAll('.recruit_contents');
    fadeInItems.forEach((fadeInItem) => {
      ScrollTrigger.create({
        trigger: '.block-company .btn_wrap',
        start: "bottom 35%",
        toggleClass: {
          targets: fadeInItem,
          className: "visible",
        },
        once: true,
      });
    });
  }

  news() {
    //NEWS
    const getArticle = new GET_ARTICLES('.news_list[data-plugin="getArticles"]');
    getArticle.makeItem = (item, content) => `
    <li>
      <a href="${item.href}">
        <p class="info"><span class="date">${item.date}</span><span class="cate">${item.category.name}</span></p>
        <p class="ttl">${item.title}</p>
        <svg><use xlink:href="#circle_arrow"></use></svg>
      </a>
    </li>`;

    getArticle.render();
  }
}

export default function HOME () {
  // テンプレートが用意したDOMContentLoaded（テンプレートインスタンスが引数に入る）
  d.addEventListener('rwd002.beforeDomready', e => domReady(e.detail) );
}